import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Chart from 'chart.js/auto';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-sippina-principal',
  templateUrl: './sippina-principal.component.html',
  styleUrls: ['./sippina-principal.component.scss']
})

export class SippinaPrincipalComponent {

  chart: any;
  yValues = [0, 0];
  label: any;
  canvas: any;
  imagen: any;
  n: any;
  boton: any;
  dominio: any;
  listaObjetivos: any;
  objetivo: any;
  valor: any;
  listaIndicadores: any;
  tabla: any;
  tablaValor: any;
  indicador: any;
  idIndicador: any;
  spinner: any;

  dominios = [
    'Este dominio muestra las características demográficas del grupo de población menor de 18 años.',
    'Se refiere al disfrute de una vida plena de las niñas, niños y adolescentes, en condiciones dignas, que garanticen su formación integral y que posibiliten el progreso de la personalidad, aptitudes, capacidad mental y física, hasta el máximo de sus posibilidades; a través de recursos, medios y aptitudes adecuados en el país, para pleno ejercicio de derechos. Si bien, alrededor de 18 millones de niñas, niños y adolescentes han avanzado en sus condiciones de bienestar, aún hay 21 millones más en situación de pobreza que deben ser incluidos a través del ejercicio de sus derechos en ámbitos centrales corno vivienda, alimentación, acceso a la educación y a los servicios de salud, cobertura de seguridad social e ingreso de los adultos. Garantizar el desarrollo integral de las niñas y niños es el principal objetivo del SIPINNA, por lo cual, en esta dimensión se han recabado 114 indicadores, que han sido agregados al SIG SIPINNA, sin embargo, sólo se integraron al sistema los 7 indicadores más relevantes.',
    'Las niñas. niños y adolescentes tienen derecho a la libertad de expresión y a manifestar su opinión sobre cuestiones que afecten todos los ámbitos de su vida. El derecho a la participación significa que puedan emitir sus opiniones y que se les escuche, así como ejercer sus otros derechos a la información y a la libertad de asociación. Un desafío inédito y central al cambio cultural es el reconocimiento de la ciudadanía plena de niñas, niños y adolescentes como sujetos de derechos. Lo anterior conlleva a garantizar espacios específicos de participación en todas las fases de la política pública, de acuerdo a su ciclo de desarrollo. Concentra 16 indicadores que describen el acceso a las tecnologías de información, como son acceso a computadora, televisión, radio e internet.',
    'Las niñas, niños y adolescentes tienen derecho a la vida y como sujetos de derecho significa que el Estado debe garantizarle el acceso a los recursos que les permita conservarla a lo largo de su ciclo de vida, atendiendo las necesidades bio-psico-sociales de conformidad a las etapas del desarrollo. Por lo tanto, la reducción de la mortalidad de niñas, niños y adolescentes, el acceso a servicios de calidad en salud en todas las edades del desarrollo, incrementar el acceso a los alimentos nutritivos y prácticas de su consumo adecuado, con especial énfasis a la primera infancia, son acciones clave para nuestro país.',
    'Las niñas. niños y adolescentes tienen derecho a la libertad de expresión y a manifestar su opinión sobre cuestiones que afecten todos los ámbitos de su vida. El derecho a la participación significa que puedan emitir sus opiniones y que se les escuche, así como ejercer sus otros derechos a la información y a la libertad de asociación. Un desafío inédito y central al cambio cultural es el reconocimiento de la ciudadanía plena de niñas, niños y adolescentes como sujetos de derechos. Lo anterior conlleva a garantizar espacios específicos de participación en todas las fases de la política pública, de acuerdo a su ciclo de desarrollo. Concentra 16 indicadores que describen el acceso a las tecnologías de información, como son acceso a computadora, televisión, radio e internet.'
  ];

  objetivos = [
    [
      'Sin Objetivos'
    ],
    [
      'Igualdad y no discriminación',
      'Pobreza y carencias sociales',
      'Educación',
      'Población Indigena',
      'Población con Discapacidad',
      'Entornos Seguros y Saludables',
      'Vivienda, Agua y Saneamiento',
      'Entornos Familiares e Institucionales'
    ],
    [
      'Identidad',
      'Vida Libre de Violencias',
      'Protección Especial',
      'Emergencias',
      'Migrantes y Refugiados',
      'Trabajo Infantil',
      'Justicia'
    ],
    [
      'Salud y Seguridad social',
      'Mortalidad',
      'Alimentación y Nutrición',
      'Desarrollo Infantil Temprano',
      'Embarazo Adolescente'
    ],
    [
      'Participación de Niñas, Niños y Adolescentes',
      'Contenidos y Medios de Comunicación',
      'Brecha Digital TIC’s',
      'Cultura, Deporte y Esparcimiento',
      'Institucionalidad'
    ]
  ]

  datosForm = new FormGroup({
    DOMINIO: new FormControl(''),
    OBJETIVO: new FormControl(''),
    INDICADOR: new FormControl('')
  })

  constructor(private router: Router, private generalService: GeneralService) { }

  ngOnInit() {
    this.spinner = true;
    this.objetivo = this.objetivos[0][0];
    this.n = '1';
    this.dominio = this.dominios[this.n - 1];
    this.listaObjetivos = this.objetivos[0];

    this.generalService.buscarSupervivencia('OBJETIVO', this.objetivo).subscribe(res => {
      this.listaIndicadores = res.body;
      console.log(this.listaIndicadores);
      if (this.listaIndicadores.length !== 0) {
        this.indicador = this.listaIndicadores[0].INDICADOR;
        this.label = this.indicador;
        this.idIndicador = this.listaIndicadores[0].ID;
        this.datosForm.value.INDICADOR = this.idIndicador;
        console.log(this.idIndicador, this.indicador);
        this.datosForm.setValue({
          'DOMINIO': this.n,
          'OBJETIVO': this.objetivo,
          'INDICADOR': this.idIndicador
        });

        this.generalService.listaSupervivenciaID(this.idIndicador).subscribe(res => {
          this.valor = res.body;
          console.log(this.valor);
          if (this.valor.length !== 0) {
            this.yValues[1] = this.valor[0].TOTAL;
            console.log(this.yValues[1]);
            this.createChart();
          } else {
            console.log('Vacio');
            this.spinner = false;
          }
        })
      } else {
        console.log('Vacio');
        this.spinner = false;
      }
      this.generarTablaDatos();
    })

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
  }

  cambioDominio() {
    this.spinner = true;
    this.n = this.datosForm.value.DOMINIO;
    this.listaObjetivos = this.objetivos[this.n - 1];
    this.objetivo = this.objetivos[this.n - 1][0];
    console.log(this.objetivo);
    this.datosForm.value.OBJETIVO = this.objetivo;
    console.log(this.datosForm.value);
    this.generalService.buscarSupervivencia('OBJETIVO', this.objetivo).subscribe(res => {
      this.listaIndicadores = res.body;
      console.log(this.listaIndicadores);
      if (this.listaIndicadores.length !== 0) {
        this.indicador = this.listaIndicadores[0].INDICADOR;
        this.label = this.indicador;
        this.idIndicador = this.listaIndicadores[0].ID;
        this.datosForm.value.INDICADOR = this.idIndicador;
        console.log(this.idIndicador, this.indicador);

        this.datosForm.setValue({
          'DOMINIO': this.n,
          'OBJETIVO': this.objetivo,
          'INDICADOR': this.idIndicador
        });

        this.generalService.listaSupervivenciaID(this.idIndicador).subscribe(res => {
          this.valor = res.body;
          console.log(this.valor);
          if (this.valor.length !== 0) {
            this.yValues[1] = this.valor[0].TOTAL;
            console.log(this.yValues[1]);
            this.chart.destroy();
            this.createChart();
          } else {
            console.log('Vacio');
            this.spinner = false;
          }
        })
      } else {
        console.log('Vacio');
        this.spinner = false;
      }
    })
    this.generarTablaDatos();
  }

  cambioObjetivo() {
    this.spinner = true;
    this.objetivo = this.datosForm.value.OBJETIVO;
    console.log(this.datosForm.value);
    this.generalService.buscarSupervivencia('OBJETIVO', this.objetivo).subscribe(res => {
      this.listaIndicadores = res.body;
      console.log(this.listaIndicadores);
      if (this.listaIndicadores.length !== 0) {
        this.indicador = this.listaIndicadores[0].INDICADOR;
        this.label = this.indicador;
        this.idIndicador = this.listaIndicadores[0].ID;
        this.datosForm.value.INDICADOR = this.idIndicador;
        console.log(this.idIndicador, this.indicador);

        this.datosForm.setValue({
          'DOMINIO': this.n,
          'OBJETIVO': this.objetivo,
          'INDICADOR': this.idIndicador
        });

        this.generalService.listaSupervivenciaID(this.idIndicador).subscribe(res => {
          this.valor = res.body;
          console.log(this.valor);
          if (this.valor.length !== 0) {
            this.yValues[1] = this.valor[0].TOTAL;
            console.log(this.yValues[1]);
            this.chart.destroy();
            this.createChart();
          } else {
            console.log('Vacio');
            this.spinner = false;
          }
        })
      } else {
        console.log('Vacio');
        this.spinner = false;
      }
    })
  }

  cambioIndicador() {
    this.spinner = true;
    console.log(this.datosForm.value);
    this.idIndicador = this.datosForm.value.INDICADOR;

    this.generalService.listaSupervivenciaID(this.idIndicador).subscribe(res => {
      this.valor = res.body;
      this.label = this.valor[0].INDICADOR;
      console.log(this.label);
      console.log(this.valor);
      if (this.valor.length !== 0) {
        this.yValues[1] = this.valor[0].TOTAL;
        console.log(this.yValues[1]);
        this.chart.destroy();
        this.createChart();
      } else {
        console.log('Vacio');
        this.spinner = false;
      }
    })
  }

  createChart() {
    this.spinner = false;
    this.chart = new Chart("MyChart", {
      type: 'bar',
      data: {
        labels: [this.label],
        datasets: [
          {
            label: "" + this.yValues[1].toLocaleString() + "",
            data: [this.yValues[1]],
            backgroundColor: '#0057ba',
            barPercentage: 0.5
          }
        ],
      },
      options: {
        aspectRatio: 1,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            align: 'center',
            labels: {
              color: '#212529',
              font: {
                size: 16,
              }
            }
          },
          title: {
            display: true,
            text: this.label,
            color: "#212529",
            align: 'center',
            font: {
              size: 16,
            }
          }
        },
        scales: {
          x: {
            ticks: {
              display: false
            },
            border: {
              color: "#212529",
              width: 2
            }
          },
          y: {
            ticks: {
              color: "#212529",
              font: {
                size: 16
              }
            },
            border: {
              color: "#212529",
              width: 2
            },
            grid: {
              color: "#212529"
            },
          }
        }
      }
    });
  }

  generarTablaDatos() {
    if (this.datosForm.value.DOMINIO === '1') {
      this.tablaValor = 'Contexto';
    } else if (this.datosForm.value.DOMINIO === '2') {
      this.tablaValor = 'Desarrollo';
    } else if (this.datosForm.value.DOMINIO === '3') {
      this.tablaValor = 'Protección';
    } else if (this.datosForm.value.DOMINIO === '4') {
      this.tablaValor = 'Supervivencia';
    } else if (this.datosForm.value.DOMINIO === '5') {
      this.tablaValor = 'Participación';
    }

    this.generalService.buscarSupervivencia('DOMINIO', this.tablaValor).subscribe(res => {
      this.tabla = res.body;
      console.log(this.tabla);
    })
  }

  descargarGrafica() {
    const link = document.createElement("a");
    this.canvas = document.getElementById('MyChart') as HTMLCanvasElement;
    this.imagen = this.canvas.toDataURL();

    link.download = "Gráfica (" + this.label + ").png";
    link.href = this.canvas.toDataURL();
    link.click();
  }

  descargarDatos() {
    this.exportToExcel();
  }

  exportToExcel(): void {
    let element = document.getElementById('reporte');
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, this.tablaValor + '.xlsx');
  }

  agregarDato() {
    this.router.navigate(['admin/sipinna/agregar-datos']);
  }

  agregarIndicador() {
    this.router.navigate(['admin/sipinna/nuevo-indicador']);
  }
}
