<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet"
  integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-geWF76RCwLtnZ8qwWowPQNguL3RmwHVBC9FhGdlKrxdiJJigb/j/68SIy3Te4Bkz" crossorigin="anonymous"></script>
<link href="https://use.fontawesome.com/releases/v5.0.13/css/all.css" rel="stylesheet" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

<div class="wrapper">
  <section
    style="background-color: white; padding-top: 40px; padding-left: 2vh; padding-right: 2vh; border-radius: 20px;">
    <div
      style="width: 100%; height: 30px; background-color: black; text-align: center; align-items: center; border-radius: 7px;">
      <label style="color: white; font-size: 18px;">Autlán de Navarro</label>
    </div>
    <form [formGroup]="datosForm">
      <div class="container">
        <div class="row" style="margin-top: 3vh;">
          <div class="col-sm" style="justify-content: center; text-align: center;">
            <img src="https://i.imgur.com/yZzEG5i.png" height="40px" width="40px">
            <div class="form-group">
              <label for="dominio"><strong>Dominio</strong></label>
              <select formControlName="DOMINIO" class="form-select" id="dominio"
                style="overflow: scroll; justify-content: center; text-align: center;" (change)="cambioDominio()">
                <option value="1">Contexto</option>
                <option value="2">Desarrollo</option>
                <option value="3">Protección</option>
                <option value="4">Supervivencia</option>
                <option value="5">Participación</option>
              </select>
            </div>
          </div>
          <div class="col-sm" style="justify-content: center; text-align: center;">
            <img src="https://i.imgur.com/WxZZAh9.png" height="40px" width="40px">
            <div class="form-group">
              <label for="objetivo"><strong>Objetivo</strong></label>
              <select formControlName="OBJETIVO" class="form-select" id="objetivo"
                style="overflow: scroll; justify-content: center; text-align: center;" (change)="cambioObjetivo()">
                <option *ngFor="let dato of listaObjetivos" value="{{dato}}">{{dato}}</option>
              </select>
            </div>
          </div>
          <div class="col-sm" style="justify-content: center; text-align: center;">
            <img src="https://i.imgur.com/WZwHlGd.png" height="40px" width="40px">
            <div class="form-group">
              <label for="indicador"><strong>Indicador</strong></label>
              <select formControlName="INDICADOR" class="form-select" (change)="cambioIndicador()" id="indicador">
                <option value="{{dato.ID}}" *ngFor="let dato of this.listaIndicadores">
                  {{dato.INDICADOR}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div style="width: 100%; height: fit-content; text-align: center; margin-top: 3vh;">{{this.dominio}}</div>
    <div class="container">
      <div class="row" style="margin-top: 3vh;">
        <div id="principal" class="col-lg"
          style="justify-content: center; text-align: center; height: 550px; margin-bottom: 3vh; display: flex;">
          <canvas id="MyChart">{{ chart }}</canvas>
          <div id="cargando" *ngIf="this.spinner === true">
            <div style="align-items: center; justify-content: center; display: flex; height: 100%;">
              <div class="spinner-border" style="color: #000000; height: 60px; width: 60px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.boton === 1" class="col-lg" style="justify-content: right; text-align: center; display: flex;">
      <button class="boton" (click)="agregarIndicador()"><i class="fa fa-plus"></i> Nuevo Indicador</button>
      <button class="boton" (click)="agregarDato()"><i class="fa fa-plus"></i> Agregar Datos</button>
    </div>
    <div class="row" style="margin-top: 2vh;">
      <div class="col-lg" style="justify-content: left; text-align: center; display: flex;">
        <button class="boton" (click)="descargarGrafica()"><i class="fa fa-download"></i> Descargar Gráfica</button>
        <button class="boton" (click)="descargarDatos()"><i class="fa fa-download"></i> Descargar Datos</button>
      </div>
    </div>
  </section>
</div>

<div class="wrapperTabla" hidden>
  <table class="table table-bordered" id="reporte" style="background-color: white;">
    <tbody class="thead-light">
      <tr>
        <th colspan="9" style="text-align: left;">
          DOMINIO: {{this.tablaValor}}
        </th>
      </tr>
      <tr>
        <th colspan="5" style="text-align: center;">
          INDICADOR
        </th>
        <th colspan="4" style="text-align: center;">
          TOTAL
        </th>
      </tr>
      <tr *ngFor="let dato of this.tabla;" style="color: gray;">
        <td colspan="5">{{this.dato.INDICADOR}}</td>
        <td colspan="4">{{this.dato.TOTAL.toLocaleString()}}</td>
      </tr>
    </tbody>
  </table>
</div>
