import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuevo-indicador',
  templateUrl: './nuevo-indicador.component.html',
  styleUrls: ['./nuevo-indicador.component.scss']
})
export class NuevoIndicadorComponent {

  objetivos = [
    [
      'Sin Objetivos'
    ],
    [
      'Igualdad y no discriminación',
      'Pobreza y carencias sociales',
      'Educación',
      'Población Indigena',
      'Población con Discapacidad',
      'Entornos Seguros y Saludables',
      'Vivienda, Agua y Saneamiento',
      'Entornos Familiares e Institucionales'
    ],
    [
      'Identidad',
      'Vida Libre de Violencias',
      'Protección Especial',
      'Emergencias',
      'Migrantes y Refugiados',
      'Trabajo Infantil',
      'Justicia'
    ],
    [
      'Salud y Seguridad social',
      'Mortalidad',
      'Alimentación y Nutrición',
      'Desarrollo Infantil Temprano',
      'Embarazo Adolescente'
    ],
    [
      'Participación de Niñas, Niños y Adolescentes',
      'Contenidos y Medios de Comunicación',
      'Brecha Digital TIC’s',
      'Cultura, Deporte y Esparcimiento',
      'Institucionalidad'
    ]
  ]

  listaObjetivos: any;
  objetivo: any;
  boton: any;
  n: any;
  dominio: any;
  spinner: any;

  datosForm = new FormGroup({
    DOMINIO: new FormControl(''),
    OBJETIVO: new FormControl(''),
    INDICADOR: new FormControl(''),
    TOTAL: new FormControl(''),
    SECCION: new FormControl(0),
    ESTATUS: new FormControl(0)
  })

  constructor(private alerts: SweetAlertService, private router: Router) { }

  ngOnInit() {
    this.spinner = false;
    this.objetivo = this.objetivos[0][0];
    this.n = '1';
    this.listaObjetivos = this.objetivos[0];

    this.datosForm.setValue({
      'DOMINIO': this.n,
      'OBJETIVO': this.objetivo,
      'INDICADOR': '',
      'TOTAL': '',
      'SECCION': 0,
      'ESTATUS': 0
    });

    if (localStorage.getItem('token')) {
      this.boton = 1;
    } else {
      this.router.navigate(['admin/sipinna']);
    }
  }

  cargar() {
    this.spinner = true;

    if (this.datosForm.value.DOMINIO !== '' &&
      this.datosForm.value.OBJETIVO !== '' &&
      this.datosForm.value.INDICADOR !== '' &&
      this.datosForm.value.TOTAL !== '') {

      if (this.datosForm.value.DOMINIO === '1') {
        this.dominio = 'Contexto';
      } else if (this.datosForm.value.DOMINIO === '2') {
        this.dominio = 'Desarrollo';
      } else if (this.datosForm.value.DOMINIO === '3') {
        this.dominio = 'Protección';
      } else if (this.datosForm.value.DOMINIO === '4') {
        this.dominio = 'Supervivencia';
      } else if (this.datosForm.value.DOMINIO === '5') {
        this.dominio = 'Participación';
      }

      this.datosForm.value.DOMINIO = this.dominio;

      this.alerts.realizado('Completado', 'El indicador se ha guardado con exito');
      this.spinner = false;
      console.log(this.datosForm.value);

    } else {
      this.alerts.alertaError('Error', 'Llene todos los campos');
      this.spinner = false;
      console.log(this.datosForm.value);
    }
  }

  cambioDominio() {
    this.n = this.datosForm.value.DOMINIO;
    this.listaObjetivos = this.objetivos[this.n - 1];
    this.objetivo = this.objetivos[this.n - 1][0];
    this.datosForm.value.OBJETIVO = this.objetivo;
    console.log(this.datosForm.value);
  }

  cambioObjetivo() {
    this.n = this.datosForm.value.DOMINIO;
    this.objetivo = this.datosForm.value.OBJETIVO;
    console.log(this.datosForm.value);
  }
}
