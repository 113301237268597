import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '../LOGIN/login/login.component';
import { IndexComponent } from '../index/index.component';
import { LogoComponent } from 'src/app/shared/components/sidenav-admin/logo/logo.component';

import { AuthEGuard } from 'src/app/core/guard/authE.guard';
import { SippinaPrincipalComponent } from '../sippina-principal/sippina-principal.component';
import { SubirDatosComponent } from '../subir-datos/subir-datos.component';
import { NuevoIndicadorComponent } from '../nuevo-indicador/nuevo-indicador.component';

const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'indice',
    component: IndexComponent,
    canActivate: [AuthEGuard],
  },
  {
    path: 'logo',
    component: LogoComponent
  },
  {
    path: 'sipinna',
    component: SippinaPrincipalComponent
  },
  {
    path: 'sipinna/agregar-datos',
    component: SubirDatosComponent
  },
  {
    path: 'sipinna/nuevo-indicador',
    component: NuevoIndicadorComponent
  }
];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule { }
