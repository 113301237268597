import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subir-datos',
  templateUrl: './subir-datos.component.html',
  styleUrls: ['./subir-datos.component.scss']
})
export class SubirDatosComponent {

  objetivos = [
    [
      'Sin Objetivos'
    ],
    [
      'Igualdad y no discriminación',
      'Pobreza y carencias sociales',
      'Educación',
      'Población Indigena',
      'Población con Discapacidad',
      'Entornos Seguros y Saludables',
      'Vivienda, Agua y Saneamiento',
      'Entornos Familiares e Institucionales'
    ],
    [
      'Identidad',
      'Vida Libre de Violencias',
      'Protección Especial',
      'Emergencias',
      'Migrantes y Refugiados',
      'Trabajo Infantil',
      'Justicia'
    ],
    [
      'Salud y Seguridad social',
      'Mortalidad',
      'Alimentación y Nutrición',
      'Desarrollo Infantil Temprano',
      'Embarazo Adolescente'
    ],
    [
      'Participación de Niñas, Niños y Adolescentes',
      'Contenidos y Medios de Comunicación',
      'Brecha Digital TIC’s',
      'Cultura, Deporte y Esparcimiento',
      'Institucionalidad'
    ]
  ]

  listaObjetivos: any;
  objetivo: any;
  listaIndicadores: any;
  indicador: any;
  boton: any;
  n: any;
  spinner: any;

  datosForm = new FormGroup({
    DOMINIO: new FormControl(''),
    OBJETIVO: new FormControl(''),
    INDICADOR: new FormControl(''),
    TOTAL: new FormControl('')
  })

  constructor(private generalService: GeneralService, private alerts: SweetAlertService, private router: Router) { }

  ngOnInit() {
    this.spinner = false;
    this.objetivo = this.objetivos[0][0];
    this.n = '1';
    this.listaObjetivos = this.objetivos[0];

    this.generalService.buscarSupervivencia('OBJETIVO', this.objetivo).subscribe(res => {
      this.listaIndicadores = res.body;
      console.log(this.listaIndicadores);
      if (this.listaIndicadores.length !== 0) {
        this.indicador = this.listaIndicadores[0].INDICADOR;
        this.datosForm.value.INDICADOR = this.indicador;
        console.log(this.indicador);

        this.datosForm.setValue({
          'DOMINIO': this.n,
          'OBJETIVO': this.objetivo,
          'INDICADOR': this.indicador,
          'TOTAL': ''
        });

      } else {
        console.log('Vacio');
      }
    })

    if (localStorage.getItem('token')) {
      this.boton = 1;
    } else {
      this.router.navigate(['admin/sipinna']);
    }
  }

  cargar() {
    this.spinner = true;

    if (this.datosForm.value.DOMINIO !== '' &&
      this.datosForm.value.OBJETIVO !== '' &&
      this.datosForm.value.INDICADOR !== '' &&
      this.datosForm.value.TOTAL !== '') {

      this.alerts.realizado('Completado', 'El dato se ha guardado con exito');
      this.spinner = false;
      console.log(this.datosForm.value);

    } else {
      this.alerts.alertaError('Error', 'Llene todos los campos');
      this.spinner = false;
      console.log(this.datosForm.value);
    }
  }

  cambioDominio() {
    this.n = this.datosForm.value.DOMINIO;
    this.listaObjetivos = this.objetivos[this.n - 1];
    this.objetivo = this.objetivos[this.n - 1][0];
    this.datosForm.value.OBJETIVO = this.objetivo;
    console.log(this.datosForm.value);
    this.generalService.buscarSupervivencia('OBJETIVO', this.objetivo).subscribe(res => {
      this.listaIndicadores = res.body;
      console.log(this.listaIndicadores);
      if (this.listaIndicadores.length !== 0) {
        this.indicador = this.listaIndicadores[0].INDICADOR;
        this.datosForm.value.INDICADOR = this.indicador;
        console.log(this.indicador);

        this.datosForm.setValue({
          'DOMINIO': this.n,
          'OBJETIVO': this.objetivo,
          'INDICADOR': this.indicador,
          'TOTAL': ''
        });

      } else {
        console.log('Vacio');
      }
    })
  }

  cambioObjetivo() {
    this.n = this.datosForm.value.DOMINIO;
    this.objetivo = this.datosForm.value.OBJETIVO;
    console.log(this.datosForm.value);
    this.generalService.buscarSupervivencia('OBJETIVO', this.objetivo).subscribe(res => {
      this.listaIndicadores = res.body;
      console.log(this.listaIndicadores);
      if (this.listaIndicadores.length !== 0) {
        this.indicador = this.listaIndicadores[0].INDICADOR;
        this.datosForm.value.INDICADOR = this.indicador;
        console.log(this.indicador);

        this.datosForm.setValue({
          'DOMINIO': this.n,
          'OBJETIVO': this.objetivo,
          'INDICADOR': this.indicador,
          'TOTAL': ''
        });

      } else {
        console.log('Vacio');
      }
    })
  }

  cambioIndicador() {
    this.datosForm.setValue({
      'DOMINIO': this.n,
      'OBJETIVO': this.objetivo,
      'INDICADOR': this.indicador,
      'TOTAL': ''
    });
  }
}
