<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">
  <link href="https://use.fontawesome.com/releases/v5.0.13/css/all.css" rel="stylesheet" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <section id="blog" class="blog" style="margin-top: 110px;">
    <div class="section-title" data-aos="fade-up">
      <h4>Nuevo indicador:</h4>
      <div class="fondo" data-aos="fade-up">
        <div style="justify-content:center; text-align:center;">
          <form [formGroup]="datosForm">
            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; ">Seleccione el dominio:</label>
            <select formControlName="DOMINIO" style="border-radius: 1vh;" (change)="cambioDominio()">
              <option value="1">Contexto</option>
              <option value="2">Desarrollo</option>
              <option value="3">Protección</option>
              <option value="4">Supervivencia</option>
              <option value="5">Participación</option>
            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold;">Seleccione el objetivo:</label>
            <select formControlName="OBJETIVO" style="border-radius: 1vh;" (change)="cambioObjetivo()">
              <option *ngFor="let dato of listaObjetivos" value="{{dato}}">{{dato}}</option>
            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; ">Escriba el nuevo indicador:</label>
            <input formControlName="INDICADOR" type="text"
              style="margin-top: 1%; border-radius: 1vh; height: 26px; border-width: 1px; width: 300px;">

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Escriba el
              total que corresponde al nuevo indicador:</label>
            <input formControlName="TOTAL" type="text"
              style="margin-top: 1%; border-radius: 1vh; height: 26px; border-width: 1px;">

            <div style="margin-top: 15px; justify-content: center; display: flex;">
              <button *ngIf="spinner === false" class="subir" style="border-radius: 1vh; width: 180px;"
                (click)="cargar()">Guardar indicador</button>

              <div class="spinner-border" role="status" *ngIf="spinner === true"></div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </section>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
